.cdm-summary {
  &.chart__container {
    /* override base style */
    .alp-card__content {
      color: #00000099;
      word-break: break-word;
    }
    /* end of override base style */
  }
}
