.filter-number-range {
  padding: 16px;
  border: solid 1px #979797;
  border-radius: 8px;
  background-color: white;
  
  .alp-text-input {
    width: 80px;

    .sc-d4l-input {
      text-align: center;
    }
  }
}