.concept-hierarchy {
  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .react-flow {
      &__node {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px !important;
        height: 50px !important;
      }
    }
  }

  &__select {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
    gap: 20px;
  }

}
