.chart__container {
  display: grid;
  gap: 24px;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
}

.imbalanced__container {
  display: grid;
  gap: 24px;
  grid-template-columns: 2fr 1fr;
}

.info__section {
  display: flex;
  justify-content: center;
  margin-top: 200px;
}
