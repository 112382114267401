.systemadmin__container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow-y: auto;

  main {
    flex: auto;
  }
}
.placeholder {
  display: block;
  width: 80px;
  overflow: hidden;
}
.flex-container {
  display: flex;
}
