.chart__container {
  display: grid;
  gap: 24px;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
}

.info__section {
  display: flex;
  justify-content: center;
  margin-top: 200px;
}

.treemap-chart-table__container {
  position: relative;
}

.drilldown-loader {
  position: absolute;
  background-color: white;
  border-radius: 24px;
  padding: 16px;
  z-index: 2;
  border: #999fcb 2px solid;
  width: 40%;
  top: 30%;
  left: 30%;
}
