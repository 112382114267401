.public-dataset-card {
  min-height: 286px;
  width: 100%;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    border-color: #000080;
  }

  .alp-card__content {
    display: flex;
    flex-direction: row;
  }

  &__chart {
    align-self: center;
    flex-shrink: 0;
    width: 248px;
    height: 264px;
  }

  &__content {
    padding: 32px 24px;
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: #e32900;
  }

  &__summary {
    margin-top: 24px;
    font-size: 16px;
    height: 150px;

    overflow: hidden;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }

  &__attributes {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 24px;
  }

  &__attribute {
    display: flex;
    flex-wrap: nowrap;

    svg {
      margin-right: 4px;
    }
  }

  &__permission-icon {
    margin-right: 8px;

    path[fill="navy"] {
      fill: #d53939;
    }
  }
}
