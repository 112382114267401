.change-my-password-dialog {
  .change-my-password-dialog__content {
    margin: 0 25px 25px;
    overflow: auto;
    color: var(--color-primary);

    .u-padding-vertical--normal {
      padding-right: 20px;
    }
  }

  .button-group-actions {
    display: flex;
    justify-content: center;
    margin: 24px 0;

    .alp-button__container {
      margin: 0 0 0 8px;
      width: 247px;
    }
  }
}
